@import "../../common/styles/variables.scss";

.organisationLandingPage {
  background-image: url("../../assets/images/login-bg3.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Noto Sans", sans-serif;
}

.updateContainer {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #c7deff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  justify-content: space-between;
  background-image: url("../../assets/images/meetings-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
}
.updateContainerdivv {
  height: 100%;
  width: 100%;
  padding: 40px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 125px;
  overflow-y: auto;
}

.backArrow {
  position: relative;
  /* top: 20px; */
  /* bottom: 120px; */
}

.backArrowTab {
  cursor: pointer;
}

.backArrowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.verifyContent {
  // margin: 32px;
  // margin-top: 100px;
  height: 100vh;
  padding: 40px;
  gap: 40px;
  // border-radius: 16px;
  border: 1px solid #ededed;
  box-shadow: 4px 4px 60px 0px #114b7426;
  background-image: url("../../assets/images/add-seeker-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: scroll;
}

.deletefieldBlock {
  :global {
    .react-tel-input .selected-flag {
      background-color: unset !important;
      border-radius: unset !important;
      padding: 5px;
      padding-left: 10px;
      border: 1px solid #ccc;
      border-right: unset !important;
      border-left: unset !important;
      border-top: unset !important;
      border-bottom: unset !important;
    }

    .react-tel-input .form-control {
      width: 100% !important;
    }

    .react-tel-input .selected-flag .iti__flag {
      width: 60px;
      height: 60px;
    }

    .react-tel-input .selected-flag .arrow {
      position: relative;
      top: 49%;
      margin-top: -3px;
      margin-left: 2px;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid #555;
    }

    .react-tel-input .selected-flag .arrow.up {
      border-top: unset;
      border-bottom: 7px solid #555;
    }

    .react-tel-input .country-list {
      width: 250px;
      text-align: left;
    }

    .react-tel-input .country-list .search-box {
      width: 210px;
      margin-left: 0px;
    }

    .react-tel-input .country-list {
      max-height: 170px;
    }
  }
}

.signInWithFaceStyles {
  background-image: url("../../assets/images/mahatria-views.webp");
  background-position: 10% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Noto Sans", sans-serif;
}

.mainLogoDiv {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  position: absolute;
  top: 30%;
  left: 20%;
  right: 60%;

  img {
    width: 448px;
    height: 109px;
  }
}

.welcomeText {
  font-size: 16px;
  font-weight: 300;
}

.otpResendDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.resend {
  color: #1859b4;
  cursor: pointer;
}

.bgImageIcon {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
}

.entrainmentLogo {
  font-size: 16px;
  width: 448px;
  height: 109px;
  position: absolute;
  margin: 0 !important;
  top: 306px;
  left: 83px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}

.icons,
.signIn {
  position: relative;
}

.signIn {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 47px;
  line-height: 30px;
}

.signInText {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.signInCaption {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.icons {
  width: 24px;
  height: 24px;
  display: none;
}

.formFields,
.signInContainer,
.signInSection1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.formFields {
  gap: 0px;
  margin-top: 30px;
}

.verifyHeading {
  font-size: 18px;
  font-weight: 600;
  color: $darkBlue;
  margin: 0px;
  text-align: left;
}

.formFields,
.signInSection1 {
  align-self: stretch;
  align-items: center;
}

.signInSection1 {
  gap: 0px;
  padding: 24px;
  align-items: start;
}

.info {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.sendOtp {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  text-align: center;
}

.button {
  cursor: pointer;
  border: 0;
  padding: 7px 24px;
  background-color: transparent;
  width: 150px;
  border-radius: 99px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  white-space: nowrap;
}

.icons1 {
  height: 24px;
  width: 24px;
  position: relative;
  display: none;
  min-height: 24px;
}

.resetPassword {
  color: $anchorTag;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.forgotPasswordResetContainer {
  flex: 1;
  position: relative;
  line-height: 26px;
  display: inline-block;
  max-width: 100%;
}

.textIcon {
  align-self: stretch;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  gap: 4px;
  max-width: 100%;
}

.cards,
.cardsnSignIn,
.signInSection2 {
  flex-direction: column;
  max-width: 100%;
}

.signInSection2 {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 16px;
}

.cards {
  background-color: #fff;
  background-position: bottom -10px center;
  background-repeat: no-repeat;
  background-size: 120% 250px;
  border: 1px solid #fff;
  background-image: url("../../assets/images/card-background.svg");
  border-radius: 16px;
  box-shadow: 4px 4px 60px 0 #114b7426;
  flex-shrink: 0;
  height: calc(100vh - 150px);
  justify-content: space-between;
  max-height: 690px;
  min-height: 400px;
  overflow-y: auto;
  width: 440px;
  z-index: 1;
}

.cardsnSignIn {
  border-radius: 16px;
  flex-shrink: 0;
  justify-content: space-between;
  z-index: 1;
  overflow-y: hidden !important;
  background: linear-gradient(
    295.95deg,
    rgba(245, 252, 255, 0.8) 5.87%,
    rgba(236, 249, 255, 0.8) 27.34%,
    rgba(255, 255, 255, 0.8) 89.26%
  );
  box-shadow: 1px 2px 40px 0px #3ba4fd1f;
  max-height: 690px;
  @media screen and (max-height: 600px) {
    background-image: none;
  }
}

.cardsnSignIn {
  width: 570px;
}

.cards,
.cardsnSignIn,
.cardsWrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.cardsWrapper {
  flex-direction: row;
  justify-content: center;
  padding: 40px;
  max-width: 100%;
}

.organisationLandingPage,
.signInWithFaceStyles {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  justify-content: end;
  gap: 109px;
  height: calc(100vh - 70px);
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: 18px;
  color: #051b46;
  align-items: flex-end;
  // position: absolute;
}

.text {
  position: relative;
  line-height: 32px;
  display: inline-block;
}

.input,
.text {
  position: relative;
  line-height: 26px;
  display: inline-block;
}

.input {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  background-color: transparent;
  color: #051b46;
  white-space: nowrap;
  padding: 5px 12px;
}

.form,
.formContainer,
.formContainerPassword {
  display: flex;
  justify-content: flex-start;
}

.formTag {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  // height: 350px;
}

.otpCardHeight {
  flex-direction: column;
  align-items: start;
  // height: 500px;
}

.fieldGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fieldGroupPassword {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.error {
  min-width: 100%;
  color: #e28619;
  font-size: 12px;
  margin-top: 10px;
  font-style: italic;
  text-align: right;
  word-wrap: break-word;
  white-space: normal;
}

.submitButton {
  color: #ffffff;
  width: 210px;
  height: 50px;
  font-size: 12px;
  padding: 7px 16px !important;
  font-family: "Noto Sans", sans-serif;
}

.resendButton {
  color: $anchorTag;
  background-image: none !important;
  padding: 7px 25px 7px 7px !important;
}

.forgotPassword {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  // margin-top: 60px;
}
.notFaceButtons {
  margin-top: 180px;
}
.loaderButtonDiv {
  margin-top: 120px;
}

.formContainer {
  align-self: stretch;
  border-radius: 1px;
  background-color: #fff;
  border: 1px solid #8a8a8a;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-top: -5px;
}

.form {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 320px;
  text-align: left;
  font-size: 16px;
  color: #051b46;
}

.eyeIcon {
  margin-right: 10px;
  cursor: pointer;
}

.errorCreds {
  min-width: 100%;
  display: flex;
  justify-content: center;
  color: #e28619;
  margin-top: 8px;
  font-size: 12px;
}

.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  /* Set your desired color here */
  -webkit-text-fill-color: #051b46;
}

.form4 {
  width: 100%;
  margin: 0 !important;
  flex-direction: column;
  gap: var(--gap-5xs);
  display: flex;
  justify-content: flex-start;

  .text {
    text-align: left;
  }
}

.OTPForm {
  width: 100%;
  margin: 0 !important;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  align-items: start;

  .text {
    text-align: center;
    font-weight: 400;
  }
}

.phoneInputField {
  width: 100%;
}

.createText {
  color: $darkBlue;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.signUpText {
  color: #1859b4;
  cursor: pointer;
}

.loginCustomInput,
.errorFieldPhone {
  padding: 10px;
  border: 1px solid #ccc !important;

  &:focus,
  &:hover {
    border-color: #007bff !important;
    outline: none;
  }
}

.errorFieldPhone {
  border: 1px solid #e28619 !important;

  &:focus,
  &:hover {
    border: 1px solid #007bff !important;
    outline: none !important;
  }
}

.loginCustomButton {
  background-color: unset !important;
  border: unset !important;

  &:focus,
  &:hover {
    border-color: #007bff;
    outline: none;
  }
}

.labelInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 320px;
}

.labelText {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: $darkBlue;
}

.deletefieldBlock {
  width: 320px;
}

.caption {
  color: #051b46;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 14px;
  justify-content: center;
  .changeMobileNumber {
    color: $anchorTag;
    cursor: pointer;
  }
}

.caption2 {
  color: #051b46;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;

  .changeMobileNumber {
    color: $anchorTag;
    cursor: pointer;
  }
}

.editIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-top: -4px;
}

.errorResend {
  min-width: 100%;
  color: #e28619;
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
  text-align: left;
}

.signInWithFace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding-top: 20px;
  gap: 15px;
  color: $darkBlue;
  // width: 300px;

  img {
    width: 180px;
    height: 180px;
    // margin-top: 30px;
  }
}

.orDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  width: 100%;
}

.signInWithFaceButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-top: 30px;
  width: 100%;
}

.submitButtonSecondary {
  color: $darkBlue !important;
  height: 50px !important;
  font-size: 16px !important;
  font-family: "Noto Sans", sans-serif;
  background-image: none !important;
  border: 1px solid $darkBlue !important;
  width: 204px !important;
}

.infinitheismLogoInCard {
  width: 300px;
}

.skipText {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $anchorTag;
  cursor: pointer;
}

.verifyBtnWidth {
  width: 150px;
}
.verifyBlock {
  height: 50px;
}

.marginTopForBtns {
  margin-top: 20px;
}

.paddingTop {
  padding-top: 24px;
}

.signInCaptionPaddingTop {
  padding-top: 30px;
}

.faceIconSignup {
  margin-top: 75px;
}

.faceIdCaptionNew {
  font-size: 16px;
  text-align: left;
}

.infinipathLogo {
  width: 100%;
}

.signInWithFaceIdBtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  // height: 120px;
  margin-top: 20px;
  gap: 10px;
}

.nonInfContainer {
  height: 65px;
  margin: auto;
  width: 250px;
}

.enrollFaceIdContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-self: stretch;
}

.getOtpBtnWidth {
  width: 150px;
}

.signInWithFaceButton {
  background-image: none !important;
  border: 1px solid #1859b4 !important;
  height: 48px !important;
  font-size: 12px;
  color: $blue !important;
  padding: 7px 16px !important;
}

.welcomeTextMob {
  display: none;
}

.mobileBackground {
  display: none;
}

.backArrow {
  position: relative;
  /* top: 20px; */
  /* bottom: 120px; */
}
.webBackArrow,
.otpwebBackArrowSignin,
.otpwebBackArrow {
  position: relative;
  top: 0px;
  width: 40px;
  height: 0px;
  left: 10px;
  cursor: pointer;
}
.otpwebBackArrow,
.otpwebBackArrowSignin {
  left: -10px;
}
.backArrowTab {
  cursor: pointer;
}
.captureButton {
  width: 210px;
  color: $white;
  padding: 7px 25px 7px 25px;
  height: 50px;
  font-size: 16px;
}
.nonInfForm {
  width: 100%;
  padding: 24px 8px 24px 8px;
}

.signInText {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.phoneDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}

.loginHeight {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  height: 20px;
  width: 40%;
}

.getOTPButtonDiv,
.getLoaderButtonDiv {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .cardsWrapper {
    margin-right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .signInWithFaceStyles {
    background-image: url("../../assets/images/mahatria-tab-pic.webp");
    background-position: 10% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Noto Sans", sans-serif;
    align-items: center;
  }
}

@media screen and (max-width: 1023px) {
  .organisationLandingPage {
    background: unset;
    align-items: center;
    justify-content: unset;
    display: unset;
  }
  .webBackArrow,
  .otpwebBackArrow {
    display: none;
  }

  .cards {
    width: 100%;
    max-width: 100%;
    background: unset;
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    margin: auto;
    height: 100%;
  }

  .cardsnSignIn {
    background: linear-gradient(
      295.95deg,
      rgba(245, 252, 255, 0.8) 5.87%,
      rgba(236, 249, 255, 0.8) 27.34%,
      rgba(255, 255, 255, 0.8) 89.26%
    );
    box-shadow: 1px 2px 40px 0px #3ba4fd1f;
    margin-bottom: 80px;
  }

  .loaderClass {
    height: 20px;
  }

  .nonInfCard {
    background-repeat: no-repeat;
    overflow-y: auto;
    background-image: url("../../assets/images/card-background.svg") !important;
    background-position: bottom 15px center;
    background-size: 200% 300px;
    max-height: 1000px;
  }

  .mobileInputCard {
    position: relative;
    top: -86px;
  }

  .cardsWrapper {
    margin-right: unset;
    padding: unset;
  }

  .welcomeText {
    display: none;
  }

  .welcomeTextMob {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
  }

  .mobileBackground {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .mobileInputCardsWrapper {
    margin-top: -23%;
  }

  .signIn {
    font-size: 20px;
  }

  // .fieldBlock {
  //   width: 100%;

  //   :global {
  //     .react-tel-input .form-control {
  //       height: 45px !important;
  //     }
  //   }
  // }

  .backArrow {
    display: block;
  }
  .bgImageIcon {
    display: none;
  }
  .error {
    min-width: 200px;
    width: 300px;
    margin: auto;
    margin-top: 10px;
  }
  .phoneErrorMobile {
    margin-top: 10px;
    width: 100%;
    min-width: 100%;
  }
  .nonInfForm {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .nonInfForm {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .mobileInputCard {
    position: relative;
    top: -86px;
  }

  .cardsWrapper {
    margin-right: unset;
    padding: unset;
  }

  .mobileInputCardsWrapper {
    margin-top: -26%;
    width: 100%;
  }

  .cards,
  .cardsnSignIn {
    width: 100%;
    max-width: 90%;
    background: unset;
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    margin: auto;
    min-height: unset;
    max-height: unset;
    margin-left: 24px;
    margin-right: 24px;
    overflow-y: hidden !important;
  }

  .cardsMobile {
    background-repeat: no-repeat;
    overflow-y: auto;
    background-image: url("../../assets/images/card-background.svg");
    background-position: bottom 15px center;
    background-size: 150% 260px;
    // max-height: 690px;
    // min-height: 590px; //commented when lint issue
    min-height: 625px;
  }
  .cardsnSignInMobile {
    border-radius: 12px;
    overflow-y: auto;
    // max-height: 690px;
    // min-height: 590px; //commented when lint issue
    background:
      linear-gradient(
          288.51deg,
          rgba(245, 252, 255, 0.4) 12.38%,
          rgba(236, 249, 255, 0.4) 33.17%,
          rgba(255, 255, 255, 0.4) 93.11%
        )
        bottom 15px center / 150% 260px no-repeat,
      url("../../assets/images/card-background.svg") bottom 15px center / 150%
        260px no-repeat;
    box-shadow: 1px 2px 40px 0px #3ba4fd1f;
    margin-bottom: 10px;
  }

  .welcomeText {
    display: none;
  }

  .welcomeTextMob {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .formTag {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .deletefieldBlock {
    width: 100%;

    :global {
      .react-tel-input .form-control {
        height: 40px !important;
      }
    }
  }

  .mobileBackground {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contentText {
    margin-top: 10px;
  }

  .labelInput {
    margin-top: 10px;
  }

  .buttonContainer {
    flex-direction: column;
    gap: 16px;
  }

  .signInWithFaceIdBtnContainer {
    align-items: center;
    gap: 16x;
    margin-top: 0px;
  }

  .otpResendDiv {
    flex-direction: column;
    margin-top: 6px;
  }

  .signIn {
    gap: unset;
    font-size: 16px;
  }

  .marginTopForBtns {
    margin-top: 5px;
  }

  .signInWithFaceButtonDiv {
    gap: 30px;
    padding-top: unset;
  }

  .signInSection1 {
    // padding-bottom: unset;
    padding: 24px;
    align-items: center;
  }

  .bgImageIcon {
    display: none;
  }

  .orDiv2 {
    width: 100%;
  }

  .orDiv {
    width: 100%;
  }
  .enrollFaceIdContainer {
    padding: 20px;
  }

  .faceIconSignup {
    margin-top: 40px;
  }

  .backArrow {
    display: block;
  }
  .faceIdCaptionNew {
    font-size: 14px;
  }

  .submitButton {
    font-size: 12px;
    line-height: 26px;
  }
  .signInWithFaceButton {
    font-size: 12px;
    line-height: 26px;
  }
  .nonInfContainer {
    width: 250px;
  }
  .getOtpBtnWidth {
    width: 150px;
  }
  .signInText {
    text-align: center;
  }
  .phoneDiv {
    gap: 10px;
    align-items: center;
  }
  .OTPForm {
    align-items: center;
  }
  .caption {
    align-items: center;
  }
  .otpCardHeight {
    align-items: center;
  }
  .mobileRes {
    width: 100%;
  }
}

// @media screen and (max-width: 500px) {
//   .mobileInputCardsWrapper {
//     margin-top: -29%;
//   }

//   .mobileInputCard {
//     top: -65px;
//   }

//   .paddingTop {
//     padding-top: unset;
//   }

//   .captureButton {
//     width: 160px;
//     padding: 9px 16px 9px 16px;
//     color: $white;
//     font-size: 12px;
//   }

// }

// @media screen and (max-width: 450px) {
//   .signInSection1 {
//     padding: 16px;
//   }

//   .formTag {
//     width: 100%;
//   }

//   .fieldBlock {
//     width: 100%;

//     :global {
//       .react-tel-input .form-control {
//         height: 45px !important;
//       }
//     }
//   }

//   .mobileInputCard {
//     top: -55px;
//   }

//   .nonInfContainer {
//     width: 186px;
//   }

//   .submitButton {
//     font-size: 12px;
//   }

//   .labelInput {
//     margin-top: 8px;
//   }

//   .buttonContainer {
//     margin-top: 24px;
//     gap: 14px;
//   }

//   .signInWithFaceButton {
//     font-size: 12px;
//     height: 40px !important;
//   }

//   .orDiv2 {
//     width: 81%;
//   }

//   .formFields {
//     gap: 0px;
//   }

//   .otpResendDiv {
//     margin-top: 50px;
//     gap: 24px;
//   }

//   .enrollFaceIdContainer {
//     padding: 16px;
//   }

//   .faceIconSignup {
//     margin-top: 23px;
//   }

//   .signInWithFaceButtonDiv {
//     gap: 24px;
//   }
//   .faceIdCaptionNew {
//     font-size: 14px;
//   }
//   .welcomeTextMob {
//     font-size: 14px;
//   }
//   .signIn {
//     font-size: 14px;
//   }
//   .nonInfForm {
//     width: 100%;
//   }
// }
@media screen and (min-width: 1024px) {
  .otpErrorPadding {
    padding-right: 50px;
  }

  // .getOTPButtonDiv {
  //   margin-top: 190px;
  // }
  // .getLoaderButtonDiv {
  //   margin-top: 170px;
  // }
}

@media screen and (max-height: 700px) {
  .notFaceButtons {
    margin-top: 100px;
  }
  .loaderButtonDiv {
    margin-top: 40px;
  }
  .otpResendDiv {
    margin-top: 0px;
  }
}
@media screen and (max-height: 740px) {
  .otpResendDiv {
    margin-top: 15px;
  }
}
// @media screen and (max-height : 630px) {
//   .buttonContainer{
//     margin-top: 7px;
//   }
// }

@media screen and (max-width: 1024px) {
}

.updateHeaderContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.updateHeader {
  // height: 56px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: $darkBlue;
  width: 100%;
  text-align: left;
  font-family: $natoSansBold;
}

.closeButton {
  display: flex;
  align-items: center;
  border: 1px solid $blue;
  padding: 7px 16px;
  width: 100px;
  border-radius: 99px;
  cursor: pointer;
  color: $blue;
  gap: 8px;
  font-size: 18px;
  position: relative;
  right: 0;
}
