.App {
  font-family: sans-serif;
  text-align: center;
}
*::-webkit-scrollbar {
  width: 5px; 
  height:5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background: #d9d9d9; 
  border-radius: 10px;
}
