.button,
.nonActiveButton {
  cursor: pointer;
  border: 0;
  padding: 7px 25px 7px 25px;
  background-color: transparent;
  // width: auto;
  border-radius: 99px;
  flex-shrink: 0;
  justify-content: flex-start;
  box-sizing: border-box;
  background-image: url("../../../assets/images/button-bg-2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.nonActiveButton {
  background-image: url("../../../assets/images/nonactive-button-bg.webp") !important;
}
.disableButton {
  border: 0;
  padding: 7px 25px 7px 25px;
  background-color: transparent;
  // width: auto;
  border-radius: 99px;
  flex-shrink: 0;
  justify-content: flex-start;
  box-sizing: border-box;
  background-image: url("../../../assets/images/buttonDisable.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .button,
  .nonActiveButton,
  .disableButton {
    padding: 7px 15px 7px 15px;
  }
}

.diffStyles{
  padding: 0px !important;
}
