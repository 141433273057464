.largeLoaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 27, 70, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.smallLoaderOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  height: 60px;
}
.divLoaderOverlay {
  position:absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top:330px;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.load {
  width: 40%;
}

.myspaceLoader{
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 9999;
  height: 40px;
  width: 450px;
}

@media screen and (max-width: 1024px) {
  .myspaceLoader{
   justify-content: center;
   width: 600px;
  }
}

@media screen and (max-width: 768px) {
  .load{
    width: 60%;
  }
  .myspaceLoader{
   width: 450px;
  }
  
}
@media screen and (max-width: 600px) {
  .load{
    width: 62%;
  }
  .myspaceLoader{
   width: 300px;
  }
  
}