@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}

/* html, body {
  overscroll-behavior: none;
  overflow: hidden;
  height: 100%;
} */


:root {
  /* fonts */
  --title-regular: "Noto Sans";

  /* font sizes */
  --title-regular-size: 16px;
  --font-size-xs: 12px;

  /* Colors */
  --text-white: #fff;
  --text-link: #1859b4;
  --text-dark-blue: #051b46;
  --color-gray-100: #8a8a8a;
  --color-crimson: #e28619;
  --color-red: #e28619;

  /* Gaps */
  --gap-base: 16px;
  --gap-21xl: 40px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-41xl: 60px;
  --gap-11xl: 30px;
  --gap-7xs: 6px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-41xl: 60px;
  --padding-6xs: 7px;
  --padding-5xl: 24px;
  --padding-11xl: 30px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-194xl: 213px;
  --padding-9xs: 4px;
  --padding-10xs: 3px;
  --padding-202xl: 221px;
  --padding-21xl: 40px;
  --padding-smi: 13px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-80xl: 99px;
  --br-11xs: 2px;
  --br-881xl: 900px;

  /* Effects */
  --top-nav: 0px 1px 20px rgba(0, 0, 0, 0.1);
}


.layoutContainer {
  display: flex;
  height: calc(100vh - 70px);
}

.portalNavigation {
  width: 70px; /* Sidebar width */
  height: calc(100vh - 70px);
  background-color: white;
  box-shadow: 0px 0px 16px 0px #1F62BF24;
}

.contentContainer {
  flex: 1; /* Takes up remaining space */
  overflow: auto;
  height: calc(100vh - 70px);
}
