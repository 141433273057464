@import './font.scss';
$darkBlue: #051b46;
$natoSansRegular: "NatoSans-Regular";
$blackBlue: #051b46;
$mahatriaName: #d9251d;
$textGrey: #888888;
$tableBorder: #e7eef3;
$lineSeparator: #a5a5a5;
$anchorTag: #1859b4;
$white: #fff;
$grey: #8a8a8a;
$lightGrey: #d9d9d9;
$lightBlue: #f3f9ff;
$secondaryGrey: #a6a6a6;
$lightBlack: #051B46;
$blue: #1859B4;
$errorText: #FF6941;
$lightGrey2 : #666666;
$primaryGreen :#65C466;
$secondaryBlue : #F4F8FB;
$secondaryRed : #F57314;
$primaryOrange: #FF7D41;
$lightGrey3 : #8A8A8A;

$natoSansBold: "NotoSans-Bold";