@import '../../common/styles/variables.scss';

.otpContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.otpInput {
  width: 40px;
  height: 50px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(231, 238, 243, 1);
  text-align: center;
  font-size: 16px;
  color: $darkBlue;
}
.otpInput:focus {
  border: 1px solid rgba(231, 238, 243, 1);
  outline: none;
}

@media screen and (max-width: 600px) {
  .otpInput {
    width: 35px;
    height: 45px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInput[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}