@import "./font.scss";
@import "./variables.scss";

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $natoSansRegular;
}